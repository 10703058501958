'use strict';

import JavaScript from './javascript.js';

export default class PictureCheck {
    constructor() {
        this.settings = {
            ready: true,
            url: '',
            container: null,
            pictureToCheck: null
        }

        this.javaScript = new JavaScript();
    }

    initialize() {
        let instance = this;

        let container = document.querySelector('.js-picture-check');
        if (container) {
            this.settings.container = container;
            this.settings.url = container.dataset.url ? container.dataset.url + '/ajax' : '';

            this.settings.pictureToCheck = container.querySelector('.picture-to-check');

            let votes = document.querySelectorAll('.picture-vote div');
            if (votes.length > 0) {
                votes.forEach(voteButton => {
                    voteButton.addEventListener('click', () => {
                        instance.loadNewPicture(instance.getPictureId(), voteButton.id);
                    });
                });
            }

            document.addEventListener('keydown', (event) => {
                let isFocusTagify = (document.querySelector('.tagify--focus'));
                if (!isFocusTagify) {
                    if (['Digit0', 'Numpad0'].includes(event.code)) {
                        instance.loadNewPicture(instance.getPictureId(), 'next');
                    } else if (['Digit1', 'Numpad1'].includes(event.code)) {
                        instance.loadNewPicture(instance.getPictureId(), 'neutral');
                    } else if (['Digit9', 'Numpad9'].includes(event.code)) {
                        instance.loadNewPicture(instance.getPictureId(), 'illegal');
                    }
                }
            });

            this.loadPicture();
        }
    }

    loadNewPicture(id, vote) {
        let instance = this;

        if (!instance.settings.ready) {
            return;
        }

        let card = this.settings.pictureToCheck.querySelector('.card');

        let category = document.getElementById('category');
        category = (category ? category.value : '');
        let keywords = document.getElementById('keywords');
        keywords = (keywords ? keywords.value : '');

        if (card) {
            $(card).slideUp('normal', () => {
                card.remove();
                instance.loadPicture(id, vote, category, keywords);
            });
        } else {
            this.loadPicture(id, vote, category, keywords);
        }
    }

    getPictureId() {
        let card = this.settings.pictureToCheck.querySelector('.card');
        return card && card.dataset.hasOwnProperty('id') ? parseInt(card.dataset.id, 10) : 0;
    }

    loadPicture(id, vote, category, keywords) {
        let instance = this;
        if (!this.settings.ready) {
            return;
        }
        this.settings.ready = false;

        let data = {
        };
        if (typeof id !== 'undefined' && parseInt(id, 10) > 0) {
            data.id = parseInt(id, 10);
        }
        if (typeof vote !== 'undefined') {
            data.vote = vote;
        }
        if (typeof category !== 'undefined') {
            data.category = category;
        }
        if (typeof keywords !== 'undefined') {
            data.keywords = keywords;
        }

        fetch(this.settings.url, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Netzwerk Antwort war nicht in Ordnung!');
            }
            return response.json();
        }).then(data => {
            if (data.success === true) {
                if (data.hasOwnProperty('total')) {
                    document.querySelector('#total').innerText = '(' + data.total + ')';
                }

                if (data.hasOwnProperty('content')) {
                    let fragment = document.createElement('div');
                    fragment.innerHTML = data.content;
                    let card = fragment.querySelector('.card');
                    if (card) {
                        instance.settings.pictureToCheck.appendChild(card);
                    }

                    instance.javaScript.bindTags();
                    instance.settings.ready = true;
                }
            } else {
                throw new Error('Es ist ein unbekannter Fehler aufgetreten!');
            }
        }).catch(error => {
            toastr.error('Ein Fehler ist aufgetreten!<br>' + error.toString(), '', {'timeOut': 0});
            console.error('Error:', error);
            instance.settings.pictureToCheck.querySelector('.spinner').innerText = 'Ein Fehler ist aufgetreten!';
            $(instance.settings.container.querySelector('.picture-vote')).hide();
        });
    }
}
