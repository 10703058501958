'use strict';
import Tools from './tools.js';

export default class SocialShare {
    initialize() {
        let instance = this;
        document.addEventListener('click', (event) => {
            let selectors = '.socialShare, .socialShareBlur';
            if (event.target && event.target.matches && event.target.matches(selectors)) {
                instance.open(event.target);
            } else if (event.target && event.target.closest && event.target.closest(selectors)) {
                instance.open(event.target.closest(selectors));
            }
        });

        ['click', 'touchstart'].forEach((addEvent) => {
            document.addEventListener(addEvent, (event) => {
                if (!event.target.closest) {
                    return;
                }

                let socialShareLi = event.target.closest('.js-socialShare li');
                if (socialShareLi) {
                    event.stopPropagation();
                    return;
                }

                let socialShare = event.target.closest('.js-socialShare');
                if (socialShare) {
                    instance.destroyContainer();
                }
            });
        });

        document.addEventListener('keydown', (event) => {
            if (event.code === 'Escape') {
                let share = document.querySelector('.js-socialShare');
                if (share) {
                    instance.destroyContainer();
                }
            }
        });
    }

    open(element) {
        this.create(element, {
            social: [
                'clipboard',
                'blogger',
                'delicious',
                'digg',
                'facebook',
                'pinterest',
                'reddit',
                'stumbleupon',
                'tumblr',
                'twitter',
                'yahoo',
                'telegram',
                'threema',
                'whatsapp'
            ],
            blur: (element.classList.contains('socialShareBlur'))
        });
    }

    parseJsonLdPicture(parent) {
        let jsonLdElement = parent.querySelector('script[type="application/ld+json"]');
        if (jsonLdElement) {
            let jsonld = JSON.parse(jsonLdElement.innerText);
            if (jsonld && jsonld['@type'] === 'ImageObject') {
                return jsonld;
            }
        }
        return {};
    }

    parseSettings(element, settings) {
        let parent = element.closest('.fancybox__content');
        if (!parent) {
            parent = document.documentElement;
        }

        let jsonld = this.parseJsonLdPicture(parent);

        let title = document.title;
        if (jsonld.hasOwnProperty('name') && jsonld.name !== '') {
            title = jsonld.name;
        }

        let description = '';
        let meta = parent.querySelector('meta[name="description"]');
        if (meta && meta.hasAttribute('content')) {
            description = meta.getAttribute('content');
        } else {
            if (jsonld.hasOwnProperty('description') && jsonld.description !== '') {
                description = jsonld.description;
            }
        }

        let defaultSettings = {
            social: [],
            title: title,
            shareUrl: '',
            description: description,
            blur: false
        };

        return Object.assign(defaultSettings, settings);
    }

    create(element, settings) {
        let instance = this;

        settings = this.parseSettings(element, settings);
        instance.createContainer(element, settings);

        let socialShare = document.querySelector('.js-socialShare');
        if (settings.blur) {
            socialShare.classList.add('opaque');
            let blurElements = document.querySelectorAll('body > *:not(.js-socialShare, script, style)')
            blurElements.forEach(blurElement => {
                blurElement.classList.add('blurred');
            })
        }
        socialShare.style.display = 'block';
        setTimeout(() => {
            socialShare.classList.add('active');
            socialShare.querySelector('ul').classList.add('active');
        }, 100);
    }

    createContainer(element, settings) {
        let instance = this;
        let siteSettings = {
            'clipboard': {text: 'Kopieren', className: 'clipboard', icon: 'fas fa-clipboard', url: '{u}'},
            'blogger': {text: 'Blogger', className: 'blogger', icon: 'fab fa-blogger-b', url: 'https://www.blogger.com/blog_this.pyra?t=&amp;u={u}&amp;n={t}'},
            'delicious': {text: 'Delicious', className: 'delicious', icon: 'fab fa-delicious', url: 'https://del.icio.us/post?url={u}&amp;title={t}'},
            'digg': {text: 'Digg', className: 'digg', icon: 'fab fa-digg', url: 'https://digg.com/submit?phase=2&amp;url={u}&amp;title={t}'},
            'facebook': {text: 'Facebook', className: 'facebook', icon: 'fab fa-facebook-f', url: 'https://www.facebook.com/sharer.php?u={u}&amp;t={t}'},
            'pinterest': {text: 'Pinterest', className: 'pinterest', icon: 'fab fa-pinterest-p', url: 'https://pinterest.com/pin/create/button/?url={u}&amp;description={d}'},
            'reddit': {text: 'Reddit', className: 'reddit', icon: 'fab fa-reddit-alien', url: 'https://reddit.com/submit?url={u}&amp;title={t}'},
            'stumbleupon': {text: 'StumbleUpon', className: 'stumbleUpon', icon: 'fab fa-stumbleupon', url: 'https://www.stumbleupon.com/submit?url={u}&amp;title={t}'},
            'tumblr': {text: 'Tumblr', className: 'tumblr', icon: 'fab fa-tumblr', url: 'https://www.tumblr.com/share/link?url={u}&name={t}&description={d}'},
            'twitter': {text: 'Twitter', className: 'twitter', icon: 'fab fa-twitter', url: 'https://twitter.com/home?status={t}%20{u}'},
            'yahoo': {text: 'Yahoo', className: 'yahoo', icon: 'fab fa-yahoo', url: 'https://bookmarks.yahoo.com/toolbar/savebm?opener=tb&amp;u={u}&amp;t={t}'},
            'telegram': {text: 'Telegram', className: 'telegram', icon: 'fab fa-telegram-plane', url: 'https://t.me/share/url?url={u}'}
        };
        let siteSettingsMobile = {
            // @todo share urls
            // 'telegram.me'=>'https://t.me/share/url?url=' . $url . '&text=' . $text . '&to=' . $phone_number,
            // 'threema'=>'threema://compose?text=' . $text . '&id=' . $user_id,

            'threema': {text: 'Threema', className: 'threema', icon: 'fas fa-comment', url: 'threema://compose?text={u}'},
            'whatsapp': {text: 'WhatsApp', className: 'whatsapp', icon: 'fab fa-whatsapp', url: 'whatsapp://send?text={u}'}
        };

        let tools = new Tools();
        if (tools.isMobileAndTabletBrowser()) {
            siteSettings = Object.assign(siteSettings, siteSettingsMobile);
        }

        let shareUrl = this.getShareUrl(element, settings);
        let items = '';
        for (let i = 0; i < settings.social.length; i++) {
            let siteKey = settings.social[i];
            if (siteSettings.hasOwnProperty(siteKey)) {
                let site = siteSettings[siteKey];

                site.url = site.url.replace('{t}', encodeURIComponent(settings.title)).replace('{u}', encodeURI(shareUrl)).replace('{d}', encodeURIComponent(settings.description));
                if (siteKey === 'clipboard') {
                    items += '<li><a href="' + site.url + '" rel="nofollow" class="' + site.className + '"><i class="' + site.icon + '"></i></a><span>' + site.text + '</span></li>';
                } else {
                    items += '<li><a href="' + site.url + '" target="_blank" rel="nofollow" class="' + site.className + '"><i class="' + site.icon + '"></i></a><span>' + site.text + '</span></li>';
                }
            }
        }

        let socialShare = document.createElement('div');
        socialShare.classList.add('js-socialShare');
        socialShare.innerHTML = '<div class="icon-container"><div class="centered"><ul>' + items + '</ul></div></div></div>';

        let links = socialShare.querySelectorAll('.icon-container a');
        if (links.length > 0) {
            links.forEach(link => {
                link.addEventListener('click', (event) => {
                    if (link.classList.contains('clipboard')) {
                        navigator.clipboard.writeText(link.getAttribute('href'));
                    } else {
                        window.open(link.getAttribute('href'), 'socialShare');
                    }
                    instance.destroyContainer();
                    event.preventDefault();
                });
            });
        }

        document.querySelector('body').append(socialShare);
    }

    getShareUrl(element, settings) {
        if (element.dataset.hasOwnProperty('href') && typeof element.dataset.href !== 'undefined' && element.dataset.href !== '') {
            return element.dataset.href;
        } else if (settings.shareUrl === '') {
            return window.location.href;
        }
        return settings.shareUrl;
    }

    destroyContainer(element) {
        let blurredElements = document.querySelectorAll('body > .blurred');
        if (blurredElements.length > 0) {
            blurredElements.forEach(blurredElement => {
                blurredElement.classList.remove('blurred');
            });
        }

        element = document.querySelector('.js-socialShare');
        if (element) {
            element.classList.remove('active');
            element.querySelector('ul').classList.remove('active');

            setTimeout(() => {
                element.style.display = 'none';
                element.remove();
            }, 300);
        }
    }
}
