'use strict';

export default class Advertising {
    constructor() {
        this.cookieName = 'popunder';
    }

    initialize() {
        // Do nothing if cookie exists
        if (this.cookieExists()) {
            return;
        }

        // Do nothing if on development
        if (location.host === 'schambereich.localhost') {
            return;
        }

        let instance = this;
        fetch('/config.json', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        }).then(response => response.json()).then(data => {
            if (data.hasOwnProperty('popUnder')) {
                if (data.popUnder.hasOwnProperty('campaign') && data.popUnder.campaign !== '') {
                    instance.popUnder(data.popUnder.campaign);
                } else if (data.popUnder.hasOwnProperty('default') && data.popUnder.default !== '') {
                    instance.popUnder(data.popUnder.default);
                }
            }
        });
    }

    cookieExists() {
        return (document.cookie.indexOf(this.cookieName) !== -1);
    }

    popUnder(popUnderRedirect) {
        let instance = this;

        let hrefs = document.querySelectorAll('[href]');
        hrefs.forEach(href => {
            href.addEventListener('click', (event) => {
                // Do nothing if cookie exists
                if (instance.cookieExists()) {
                    return;
                }

                let href = event.target.href;

                // Do not redirect [undefined, empty, hash, external links]
                let noExternalLinks = (event.target.hasOwnProperty('target') && event.target.target === '_blank');
                if (typeof href === 'undefined' || href === ''|| href.indexOf('#') !== -1 || noExternalLinks) {
                    return;
                }

                let invalidNames = ['search'];
                for(let i = 0; i < invalidNames.length; i++) {
                    if (event.target.name && event.target.name === invalidNames[i]) {
                        return;
                    }
                }

                let invalidClasses = ['form-control'];
                for(let i = 0; i < invalidClasses.length; i++) {
                    if (event.target.classList.contains(invalidClasses[i])) {
                        return;
                    }
                }

                let timeExpires = 60 * 60 * 5; // In seconds
                document.cookie = instance.cookieName + '=1;expires=' + new Date((new Date()).getTime() + timeExpires * 1000).toGMTString() + ';path=/';

                let currentLocation = '';
                if (href.indexOf('://') !== -1) {
                    currentLocation = href;
                } else {
                    currentLocation = window.location.origin + href;
                }

                if (currentLocation !== '') {
                    event.preventDefault();
                    window.open(currentLocation);
                    window.location.href = popUnderRedirect;
                }
            });
        });
    }
}
