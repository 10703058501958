'use strict';

import '../css/app.scss';
import Ready from './ready.js';
import JavaScript from './javascript.js';
import ListFilterRedirect from './listFilterRedirect.js';
import NavigationState from './navigationState.js';
import AppFancyBox from './fancybox.js';
import AdministrationPanel from './administration-panel.js';
import Favorite from './favorite.js';
import Rating from './rating.js';
import Comment from './comment.js';
import PictureList from './picture-list.js';
import PictureCheck from './picture-check.js';
import PictureShow from './picture-show.js';
import SocialShare from './socialShare.js';
import ButtonRemove from './button-remove.js';
import Advertising from './advertising.js';
import OneRevive from './one-revive.js';
// import OEmbed from './oembed.js';
import {Editor} from './ckeditor';

async function loadJQuery() {
    const {default: jQuery} = await import(
        /* webpackChunkName: "jquery" */
        'jquery/dist/jquery'
    );
    return jQuery;
}
async function loadBootstrap() {
    const {default: Bootstrap} = await import(
        /* webpackChunkName: "bootstrap" */
        'bootstrap/dist/js/bootstrap'
    );
    return Bootstrap;
}

async function loadFontAwesome() {
    const {default: FontAwesome} = await import(
        /* webpackChunkName: "font-awesome" */
        './font-awesome.js'
    );
    return FontAwesome;
}

loadJQuery().then((jQuery) => {
    // Fix for window element https://github.com/symfony/webpack-encore/pull/54
    global.$ = global.jQuery = jQuery;

    loadBootstrap().then((bootstrap) => {
        // @todo Should not be global
        global.bootstrap = bootstrap;

        loadFontAwesome().then(() => {
        });

        // require('ckeditor/ckeditor.js');

        // @todo Should not be global
        global.toastr = require('toastr/toastr.js');
        toastr.options = {
            'closeButton': true,
            'debug': false,
            'newestOnTop': true,
            'progressBar': true,
            'positionClass': 'toast-top-right',
            'preventDuplicates': false,
            'onclick': null,
            'showDuration': 300,
            'hideDuration': 1000,
            'timeOut': 5000,
            'extendedTimeOut': 2000,
            'showEasing': 'swing',
            'hideEasing': 'swing',
            'showMethod': 'slideDown',
            'hideMethod': 'slideUp'
        };

        // @todo Should not be global
        global.zxcvbn = require('zxcvbn');

        require('./javascript.js');
        require('./ckeditor.js');
        require('./user.js');

        Ready(() => {
            const javaScript = new JavaScript();
            javaScript.initialize();

            new ListFilterRedirect('.js-story-list', {category: '#category', sort: '#sort'});
            new ListFilterRedirect('.js-survey-list', {category: '#category', sort: '#sort'});
            new NavigationState();

            const fancyBox = new AppFancyBox();
            fancyBox.initialize();

            const administrationPanel = new AdministrationPanel();
            administrationPanel.initialize();

            const favorite = new Favorite();
            favorite.initialize();

            const rating = new Rating();
            rating.initialize();

            const pictureList = new PictureList();
            pictureList.initialize();

            const pictureCheck = new PictureCheck();
            pictureCheck.initialize();

            const pictureShow = new PictureShow();
            pictureShow.initialize();

            const socialShare = new SocialShare();
            socialShare.initialize();

            const buttonRemove = new ButtonRemove();
            buttonRemove.initialize();

            const advertising = new Advertising();
            advertising.initialize();

            const oneRevive = new OneRevive();
            oneRevive.initialize();

            // const oEmbed = new OEmbed();
            // oEmbed.initialize();

            // CkEditor
            let editor = new Editor();

            let editorsFull = document.querySelectorAll('.editor-full');
            if (editorsFull.length > 0) {
                editorsFull.forEach(element => {
                    editor.initializeEditor(element, 'full');
                });
            }

            let editorsMinimal = document.querySelectorAll('.editor-minimal');
            if (editorsMinimal.length > 0) {
                editorsMinimal.forEach(element => {
                    editor.initializeEditor(element, 'minimal');
                });
            }

            let editorsPicture = document.querySelectorAll('.editor-picture');
            if (editorsPicture.length > 0) {
                editorsPicture.forEach(element => {
                    editor.initializeEditor(element, 'minimal', {
                        characterMax: 500
                    });
                });
            }

            let editorsStory = document.querySelectorAll('.editor-story');
            if (editorsStory.length > 0) {
                editorsStory.forEach(element => {
                    editor.initializeEditor(element, 'full');
                });
            }

            const comment = new Comment();
            comment.initialize(editor);
        });
    });
});
