'use strict';

import {Fancybox} from '@fancyapps/ui/src/Fancybox/Fancybox.js';
import FancyboxDe from '@fancyapps/ui/src/Fancybox/l10n/de';
import Comment from './comment.js';
import PictureShow from './picture-show.js';

export default class AppFancyBox {
    initialize() {
        this.defaults();
        this.plugins();

        let container = document.querySelector('.js-picture-list');
        if (!container) {
            this.bindFancyBoxPicture();
        }
    }

    defaults() {
        Fancybox.defaults.Hash = false;
        Fancybox.defaults.l10n = FancyboxDe;
        Fancybox.defaults.autoFocus = false; // Set focus on first focusable element after displaying content
        Fancybox.defaults.trapFocus = false; // Trap focus inside Fancybox
    }

    plugins() {
        Fancybox.Plugins.Toolbar.defaults.items.openInNewWindow = {
            type: "button",
            class: "fancybox__button--openInNewWindow",
            label: "Zur Seite",
            html: '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" class="svg-inline--fa fa-external-link-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>',
            click: function (event) {
                event.preventDefault();
                let slide = this.fancybox.getSlide();
                if (slide.$trigger && slide.$trigger.dataset && slide.$trigger.dataset.hasOwnProperty('url')) {
                    window.open(slide.$trigger.dataset.url, '_blank');
                    // window.location = slide.$trigger.dataset.url;
                } else if (slide.hasOwnProperty('src')) {
                    window.open(slide.src, '_blank');
                    // window.location = slide.src;
                }
            },
        };
    }

    bindFancyBoxPicture(callbackLoad) {
        // let Toolbar = Fancybox.Plugins.Toolbar;

        // Fancybox.defaults.Image = { zoom: true };
        const comment = new Comment();
        Fancybox.bind('[data-fancybox="picture"]', {
            preload: 1, // Number of slides to preload before and after active slide. Default: 1
            infinite: false, // Should navigation be infinite

            // maxWidth    : 700,
            // maxHeight   : 500,
            // fitToView   : false,
            // width       : '70%',
            // height      : '70%',
            // autoSize    : false,
            // closeClick  : false,
            // openEffect  : 'none',
            // closeEffect : 'none',

            groupAll: true, // Group all items
            on: {
                // ready: (fancybox) => {
                //     console.log(`fancybox #${fancybox.id} is ready!`);
                // },
                "Carousel.createSlide": (fancybox, carousel, slide) => {
                    // Good for preloading last slide
                    if (callbackLoad instanceof Function && slide.index === fancybox.items.length - 1) {
                        callbackLoad(fancybox, carousel, slide);
                    }

                    const pictureShow = new PictureShow();
                    (async function(slide) {
                        let retry = 0;
                        let interval = setInterval(() => {
                            if (slide.state === 'done') {
                                clearInterval(interval);

                                let show = slide.$el.querySelector('.js-picture-show');
                                if (show) {
                                    pictureShow.initializeElement(show);
                                }

                                let jsComment = slide.$el.querySelector('.js-comment');
                                if (jsComment) {
                                    comment.initializeElement(null, jsComment);
                                }
                            }

                            // Kill it, it will be never comes
                            if (retry >= 200) {
                                clearInterval(interval);
                            }
                            retry++;
                        }, 500);

                        return 0;
                    })(slide);
                },
                /*"Carousel.selectSlide": (fancybox, carousel, slide) => {
                    // Good for last slide
                    if (callbackLoad instanceof Function && slide.index === fancybox.items.length - 1) {
                        callbackLoad(fancybox, carousel, slide);
                    }
                },*/
                // done: (fancybox, slide) => {
                //     console.log(`done!`, slide);
                // },
            },

            Thumbs: {
                autoStart: false,
                Carousel: {
                    fill: true,
                    center: true,
                },
            },

            Toolbar: {
                autoEnable: false, // Disable to for HTML
                display: [
                    {id: 'prev', position: 'left'},
                    {id: 'counter', position: 'left'},
                    {id: 'next', position: 'left'},

                    {id: 'openInNewWindow', position: 'right'},
                    {id: 'slideshow', position: 'right'},
                    {id: 'thumbs', position: 'right'},
                    {id: 'fullscreen', position: 'right'},
                    // {id: 'zoom', position: 'right'},
                    {id: 'download', position: 'right'},
                    {id: 'close', position: 'right'},
                ],
            },

            // Fullscreen
            // Image: {
            //     zoom: false,
            // },
            // fullscreen: {
            //     autoStart: true,
            // },
        });
    }
}
