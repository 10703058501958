'use strict';

export default class OneRevive {
    constructor() {
        this.classFixed = 'one-revive-fixed';
    }

    initialize() {
        let instance = this;
        let elements = document.querySelectorAll('.one-revive');
        if (elements.length > 0) {
            elements.forEach(element => {
                instance.initializeFixed(element);

                window.addEventListener('scroll', (event) => {
                    instance.fixedScroll(element);
                });
                window.addEventListener('resize', (event) => {
                    instance.initializeFixed(element);
                });
            });
        }
    }

    initializeFixed(element) {
        let isFixed = element.classList.contains(this.classFixed);
        if (isFixed) {
            this.fixedSet(0);
        }

        element.dataset.settings = JSON.stringify({
            top: element.getBoundingClientRect().top + window.scrollY - 70,
            width: element.clientWidth
        });

        if (isFixed) {
            this.fixedSet(element, 1);
        }
    }

    fixedScroll(element) {
        let scroll = window.scrollY;
        let isFixed = element.classList.contains(this.classFixed);
        let settings = JSON.parse(element.dataset.settings);

        if (!isFixed) {
            settings.top = element.getBoundingClientRect().top + window.scrollY - 70;
            element.dataset.settings = JSON.stringify(settings);
        }

        if (scroll > settings.top && !isFixed) {
            this.fixedSet(element, 1);
        } else if (scroll < settings.top && isFixed) {
            this.fixedSet(element, 0);
        }
    }

    fixedSet(element, isFixed) {
        if (isFixed) {
            let settings = JSON.parse(element.dataset.settings);
            element.classList.add(this.classFixed);
            element.style.width = settings.width;
            element.parentNode.style.height = element.clientHeight;
        } else {
            element.classList.remove(this.classFixed);
            element.style.width = 'auto';
        }
    }
}
