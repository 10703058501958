'use strict';

import InfiniteScroll from './infinite-scroll.js';
import AppFancyBox from './fancybox.js';
import {Thumbs} from "@fancyapps/ui/src/Fancybox/plugins/Thumbs/Thumbs";

export default class PictureList {
    initialize() {
        let instance = this;

        let container = document.querySelector('.js-picture-list');
        if (!container) {
            return;
        }

        this.checkUrlParameter(container);

        const infiniteScroll = new InfiniteScroll(container, {
            url: container.dataset.url,
            // timeoutReload: false,

            // offsetBottom: 200,
            // offsetBottom: -200,
            // loadOnScrolling: false,
            targetSelector: '.items',
            // targetClasses: '',
            loadMoreText: 'Weitere laden',
            // loadMoreClasses: 'load-more'
            // loadingText: '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>',
            loadingText: '<div class="loading spinner1"><div class="dot"></div><div class="dot"></div><div class="dot"></div></div>',
            // loadingClasses: '',
            // errorClasses: 'error',
            request: {
                method: 'GET',
                headers: {
                    // 'Content-Type': 'application/json',
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    // 'accept': 'application/json'
                    'accept': 'text/html'
                },
            },
            beforeRequest: (instanceScroll, requestData) => {
                let category = instanceScroll.data.container.querySelector('#category').value
                if (category && category !== '') {
                    requestData.category = category;
                }

                let sort = instanceScroll.data.container.querySelector('#sort').value
                if (sort && sort !== '') {
                    requestData.sort = sort;
                }

                return requestData;
            },
            // beforeAppendResults: (instanceScroll, data) => {
            // },
            // appendResults: (instanceScroll, data) => {
            // },
            afterAppendResults: (instanceScroll, data, specialArguments) => {
                /*let div = document.createElement('div');
                div.innerHTML = instance.getHtmlFromAjax(response);
                if (div.children.length > 0) {
                    for (let i = 0; i < div.children.length; i++) {
                        div.children[i].querySelector('img').addEventListener('error', (event) => {
                            event.target.src = '/images/missing.png';
                        }, true);

                        instance.$object.items.append(div.children[i]);
                    }
                }
                */
                if (specialArguments) {
                    instance.appendNewItemsToFancybox(instanceScroll, specialArguments.fancybox, specialArguments.carousel, specialArguments.slide);
                }
            },
        });

        const fancyBox = new AppFancyBox();
        fancyBox.bindFancyBoxPicture((fancybox, carousel, slide) => {
            infiniteScroll.loadItems({
                fancybox: fancybox,
                carousel: carousel,
                slide: slide,
            });
        });

        container.querySelector('#category').addEventListener('change', (event) => {
            let category = event.target.value;

            // Update title
            let categoryTitle = 'Bilder';
            if (category !== '') {
                categoryTitle = (event.target.options[event.target.selectedIndex].text + ' ' || '') + categoryTitle;
            }
            container.querySelector('.title').innerHTML = categoryTitle;

            // Update history
            let url = document.location.pathname + (category !== '' ? '?category=' + category : '');
            window.history.pushState({'category': category}, '', url);
            infiniteScroll.reset();
        });

        container.querySelector('#sort').addEventListener('change', (event) => {
            infiniteScroll.reset();
        });

        window.onpopstate = (PopStateEvent) => {
            if (PopStateEvent.state) {
                let category = instance.filterCategory(container, PopStateEvent.state.category);
                container.querySelector('#category').value = category;
                infiniteScroll.reset();
            }
        };
    }

    filterCategory(container, category) {
        let categoryElement = container.querySelector('#category');
        if (!categoryElement.querySelector('[value="' + category + '"]')) {
            category = '';
        }
        return category;
    }

    checkUrlParameter(container) {
        let category = this.getParameterByName('category', location.search);
        if (category !== null && category !== '') {
            category = this.filterCategory(container, category);
            container.querySelector('#category').value = category;
            let url = document.location.pathname + (category !== '' ? '?category=' + category : '');
            window.history.replaceState({'category': category}, '', url);
        }
    }

    getParameterByName(name, url) {
        name = name.replace(/[\[\]]/g, "\\$&");
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        let results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    /**
     * Append new items if fancybox exists and already open
     */
    appendNewItemsToFancybox(infiniteScroll, fancybox, carousel, slide) {
        if (!fancybox || !(fancybox.items.length < infiniteScroll.data.targetContainer.children.length)) {
            return;
        }

        fancybox.showLoading(slide);

        let items = infiniteScroll.data.targetContainer.querySelectorAll('.item > a');
        if (items.length > fancybox.items.length) {
            for (let i = fancybox.items.length; i < items.length; i++) {
                let type = items[i].dataset.hasOwnProperty('type') ? items[i].dataset.type : '';
                let item = {
                    '$trigger': items[i],
                    '$thumb': items[i].querySelector('img'),
                    type: type,
                    filter: type === 'ajax' ? '#content' : '',
                    id: items[i].id,
                    src: items[i].href,
                    thumb: items[i].querySelector('img').src,
                    downloadSrc: items[i].dataset.downloadSrc ?? '',
                    caption: '',
                    index: i
                };
                fancybox.items[i] = item;
                fancybox.Carousel.slides[i] = item;
            }

            fancybox.Carousel.updatePage();
            fancybox.plugins.Toolbar.update();

            // Slideshow
            if (fancybox.plugins.Toolbar.Slideshow.isActive()) {
                fancybox.plugins.Toolbar.Slideshow.deactivate();

                fancybox.plugins.Toolbar.Slideshow.activate();
                if (fancybox.getSlide().state !== 'done') {
                    fancybox.plugins.Toolbar.Slideshow.setTimer();
                }
            }

            // Thumbnails
            if (fancybox.plugins.Thumbs && fancybox.plugins.Thumbs.Carousel) {
                let isVisible = fancybox.plugins.Thumbs.state === 'visible';
                // fancybox.plugins.Thumbs.onClosing();
                fancybox.plugins.Thumbs.cleanup();
                fancybox.plugins.Thumbs = new Thumbs(fancybox);
                // fancybox.plugins.Thumbs.init();
                if (isVisible) {
                    fancybox.plugins.Thumbs.toggle();
                }

                // fancybox.plugins.Thumbs.onPrepare();
                // fancybox.plugins.Thumbs.create();
            }
        }

        fancybox.hideLoading(slide);
    }
}
