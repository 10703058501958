'use strict';

export default class NavigationState {
    constructor() {
        this.state = {};
        this.expressionTarget = /#/gi;

        const navToggles = document.querySelectorAll('#sidebar .nav .dropdown-toggle');
        if (navToggles.length > 0) {
            this.getState();
            for (const navToggle of navToggles) {
                this.bindUpdateState(navToggle);
                this.toggleState(navToggle);
            }
        }
    }

    getState() {
        try {
            this.state = JSON.parse(localStorage.getItem('navigationState') || '{}');
        } catch (error) {
            this.state = {};
        }
    }

    saveState() {
        localStorage.setItem('navigationState', JSON.stringify(this.state));
    }

    bindUpdateState(navToggle) {
        const instance = this;
        navToggle.addEventListener('click', (event) => {
            instance.updateState(event.target);
            instance.saveState();
        });
    }

    updateState(navToggle) {
        const target = navToggle.dataset.bsTarget.replace(this.expressionTarget, '');
        this.state[target] = !navToggle.classList.contains('collapsed');
    }

    toggleState(navToggle) {
        const target = navToggle.dataset.bsTarget.replace(this.expressionTarget, '');
        if (typeof this.state[target] !== 'undefined') {
            const drawer = document.getElementById(target);
            if (drawer) {
                if (this.state[target]) {
                    navToggle.classList.remove('collapsed');
                    drawer.classList.add('show');
                } else {
                    navToggle.classList.add('collapsed');
                    drawer.classList.remove('show');
                }
            }
        }
    }
}
