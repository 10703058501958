'use strict';

export default class Rating {
    initialize() {
        let instance = this;
        document.addEventListener('change', (event) => {
            let selectors = '.js-rating';
            if (event.target && event.target.matches && event.target.matches(selectors)) {
                instance.updateRating(event.target);
            } else if (event.target && event.target.closest && event.target.closest(selectors)) {
                instance.updateRating(event.target.closest(selectors));
            }
        });
    }

    updateRating(element) {
        let instance = this;
        let rating = element.querySelector('.rating');
        if (rating.dataset.hasOwnProperty('path') && rating.dataset.path !== '') {
            let rated = parseInt(rating.querySelector('input[name=rating]:checked').value, 10);
            let url = rating.dataset.path;
            let id = parseInt(rating.dataset.hasOwnProperty('id') ? rating.dataset.id : 0, 10);
            let data = {
                extId: id,
                rated: rated,
            };

            fetch(url, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not OK');
                }
                return response.json();
            }).then(response => {
                if (parseInt(response.error, 10) > 0) {
                    throw new Error('Response error');
                } else {
                    toastr.success('Bewertung gespeichert');
                    instance.updateShow(rating, url, id);
                }
            }).catch((error) => {
                toastr.error('Bewertung nicht gespeichert werden!', '', {'timeOut': 0});
                console.error('Error:', error);
                reject(error);
            });
        } else {
            toastr.error('Bewertung nicht gespeichert werden!', '', {'timeOut': 0});
        }
    }

    updateShow(rating, url, id) {
        let showRating = rating.parentNode.parentNode.querySelector('.show-rating');
        if (showRating) {
            url = url + '/show?extId=' + id;
            fetch(url, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    // 'Accept': 'application/json',
                    'Accept': 'text/html',
                }
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not OK');
                }
                return response.text();
            }).then(response => {
                if (response.trim() === '') {
                    throw new Error('Response was empty');
                }

                let div = document.createElement('div');
                div.innerHTML = response;
                div = div.querySelector('div')
                if (!div) {
                    throw new Error('Response element not found');
                }

                showRating.innerHTML = div.innerHTML;
            }).catch((error) => {
                toastr.error('Bewertungen konnten nicht neu geladen werden!', '', {'timeOut': 0});
                console.error('Error:', error);
                reject(error);
            });
        }
    }
}
