'use strict';

export default class Favorite {
    initialize() {
        let instance = this;
        document.addEventListener('click', (event) => {
            let selectors = '.js-favorite-toggle';
            if (event.target && event.target.matches && event.target.matches(selectors)) {
                instance.updateFavorite(event.target);
            } else if (event.target && event.target.closest && event.target.closest(selectors)) {
                instance.updateFavorite(event.target.closest(selectors));
            }
        });
    }

    updateFavorite(element) {
        if (element.dataset.hasOwnProperty('path') && element.dataset.path !== '') {
            let url = element.dataset.path;
            let id = parseInt(element.dataset.hasOwnProperty('id') ? element.dataset.id : 0, 10);
            let data = {
                extId: id,
                isFavorite: element.classList.contains('isFavorite'),
            };

            fetch(url, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not OK');
                }
                return response.json();
            }).then(response => {
                if (parseInt(response.error, 10) > 0) {
                    throw new Error('Response error');
                } else {
                    element.classList.toggle('isFavorite');
                    toastr.success('Favorit ' + (data.isFavorite ? 'entfernt' : 'hinzugefügt'));
                }
            }).catch((error) => {
                toastr.error('Favorit konnte nicht gespeichert werden!', '', {'timeOut': 0});
                console.error('Error:', error);
                reject(error);
            });
        } else {
            toastr.error('Favorit konnte nicht gespeichert werden!', '', {'timeOut': 0});
        }
    }
}
