'use strict';

// new ListFilterRedirect('.js-list', {category: '#category', sort: '#sort'});
export default class ListFilterRedirect {
    constructor(selector, filter) {
        let instance = this;
        let element = document.querySelector(selector);
        if (element) {
            for (const [key, value] of Object.entries(filter)) {
                let elementOption = element.querySelector(value);
                if (elementOption) {
                    elementOption.addEventListener('change', () => {
                        instance.redirectToList(element, filter);
                    });
                }
            }
        }
    }

    redirectToList(element, filter) {
        for (const [key, value] of Object.entries(filter)) {
            filter[key] = element.querySelector(value).value
        }

        let query = new URLSearchParams(filter).toString();
        window.location = location.pathname + (query !== '' ? '?' + query : '');
    }
}
