'use strict';

import Tools from './tools.js';

export default class ButtonRemove {
    initialize() {
        let instance = this;
        this.tools = new Tools();

        document.addEventListener('click', (event) => {
            let selectors = '.js-button-remove';
            if (event.target && event.target.matches && event.target.matches(selectors)) {
                instance.safeRemove(event.target);
            } else if (event.target && event.target.closest && event.target.closest(selectors)) {
                instance.safeRemove(event.target.closest(selectors));
            }
        });
    }

    safeRemove(element) {
        let url = element.dataset.href;
        let id = parseInt(element.dataset.id, 10);
        let hideElement = element.dataset.hide;
        let data = {
            id: id
        };

        this.tools.dialogConfirm('Bestätigung', 'Bist du dir sicher?', () => {
            fetch(url, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Netzwerk Antwort war nicht in Ordnung!');
                }
                return response.json();
            }).then(data => {
                if (data.error === 0) {
                    toastr.success('Eintrag ' + id + ' wurde entfernt.');
                    this.tools.findElementAndSlideUp(hideElement, element);
                    this.tools.redirectUrlByElement(element);
                } else if (data.error === 1 && data.hasOwnProperty('redirect') && data.redirect !== '') {
                    document.location.href = data.redirect;
                } else {
                    throw new Error('Es ist ein unbekannter Fehler aufgetreten!');
                }
            }).catch(error => {
                toastr.error('Eintrag wurde nicht gelöscht!<br>' + error.toString() + ' (ID: ' + id + ')', '', {'timeOut': 0});
                console.error('Error:', error);
                reject(error);
            });
        });
    }
}
