'use strict';

export default class InfiniteScroll {
    constructor(container, settings) {
        this.data = {
            container: container,
            targetContainer: null,
            actions: null,
            loadMoreButton: null,
            loadingAnimation: null,
            errorContainer: null,
            fetchRequest: null,
            scrollTicking: false,
            ready: true
        };

        this.settings = {
            url: '',
            page: 1,

            timeoutReload: true,
            offsetBottom: 50,
            loadOnScrolling: true,
            targetSelector: '',
            targetClasses: '',
            loadMoreText: 'Load more',
            loadMoreClasses: 'load-more btn btn-primary',
            loadingText: 'Loading...',
            loadingClasses: 'loading spinner1',
            errorClasses: 'error',
            request: {},
            beforeRequest: null,
            beforeAppendResults: null,
            appendResults: null,
            afterAppendResults: null
        }
        if (settings) {
            this.settings = {...this.settings, ...settings};
        }

        this.createTargetContainer();
        this.createActionBar();
        this.bindScrolling();
        this.checkAndLoadItems(true);

        return this;
    }

    bindScrolling() {
        let instance = this;
        document.addEventListener('scroll', function(event) {
            if (!instance.data.scrollTicking) {
                window.requestAnimationFrame(function() {
                    instance.data.scrollTicking = false;
                    instance.checkAndLoadItems(false);
                });
                instance.data.scrollTicking = true;
            }
        });
    }

    checkAndLoadItems(forceLoading) {
        if (typeof forceLoading === 'undefined') {
            forceLoading = false;
        }

        let posBinder = parseInt(this.data.loadMoreButton.getBoundingClientRect().top);
        let windowBottom = window.scrollY + window.innerHeight;

        let binderUpperWindow = (posBinder <= 0);
        let binderInWindow = (posBinder > 0 && windowBottom > (posBinder - this.settings.offsetBottom));
        let scrollLoading = (this.settings.loadOnScrolling && (binderUpperWindow || binderInWindow));
        if (scrollLoading || forceLoading) {
            if (this.data.ready === true) {
                this.loadItems();
            }
        }
    }

    createDivElement(text, classes) {
        let element = document.createElement('div');
        if (classes) {
            classes.split(' ').forEach(className => {
                className = className.trim();
                className && element.classList.add(className);
            });
        }
        element.innerHTML = text;
        return element;
    }

    createTargetContainer() {
        if (this.settings.targetSelector) {
            this.data.targetContainer = this.data.container.querySelector(this.settings.targetSelector);
        }
        if (!this.data.targetContainer) {
            this.data.targetContainer = this.createDivElement('', this.settings.targetClasses);
            this.data.container.append(this.data.targetContainer);
        }
    }

    createActionBar() {
        let instance = this;
        let actions = document.createElement('div');
        actions.classList.add('actions');

        this.data.loadMoreButton = this.createDivElement(this.settings.loadMoreText, this.settings.loadMoreClasses);
        actions.append(this.data.loadMoreButton);

        this.data.loadingAnimation = this.createDivElement(this.settings.loadingText, this.settings.loadingClasses);
        actions.append(this.data.loadingAnimation);

        this.data.errorContainer = this.createDivElement('', this.settings.errorClasses);
        this.data.errorContainer.style.display = 'none';
        actions.append(this.data.errorContainer);

        this.data.actions = actions;
        this.data.container.append(this.data.actions);

        this.data.loadMoreButton.addEventListener('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            instance.checkAndLoadItems(true);
        });
    }

    isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    resolveAfterSeconds(seconds) {
        console.log('Resolve after ' + seconds +  ' seconds:', 'calling');
        let result = new Promise(resolve => {
            setTimeout(() => {
                resolve('resolved');
            }, seconds * 1000);
        });
        console.log('Resolve after ' + seconds +  ' seconds:', result);
        return result;
    }

    async fetchData(url = '', data = {}) {
        // const justWait = await this.resolveAfterSeconds(1);

        let request = {
            method: 'GET',
        };
        request = {...request, ...this.settings.request};

        if (request.method.toLowerCase() === 'get') {
            // @todo url parse query parameter
            url += (url.indexOf('?') > -1 ? '&' : '?') + new URLSearchParams(data).toString();
        }

        if (request.method.toLowerCase() === 'post') {
            if (request.headers['Content-Type'] === 'application/json') {
                request.body = JSON.stringify(data);
            } else {
                request.body = new URLSearchParams(data).toString();
            }
        }

        return await fetch(url, request).then((response) => {
            if (response.headers.get('Content-Type')) {
                let contentTypeArray = response.headers.get('Content-Type').split(';');
                if (contentTypeArray[0] === 'application/json') {
                    return response.json();
                }
            }
            return response.text();
        }).catch(reason => {
            console.error('fetch error', reason);
            this.showError('fetch error' + reason);
        });
    }

    loadItems(specialArguments) {
        let instance = this;
        this.showLoadingAnimation();

        let fetchData = {
            page: this.settings.page,
        };
        if (this.isFunction(this.settings.beforeRequest)) {
            fetchData = this.settings.beforeRequest(this, fetchData);
        }

        this.data.fetchRequest = this.fetchData(this.settings.url, fetchData).then(data => {
            instance.hideLoadingAnimation();
            instance.settings.page += 1;

            if (this.isFunction(this.settings.beforeAppendResults)) {
                instance.settings.beforeAppendResults(this, data);
            }

            if (this.isFunction(this.settings.appendResults)) {
                instance.settings.appendResults(this, data);
            } else if (typeof data === 'object') {
                instance.showError('Content is a object!<br>Provide your own appendResults() in settings!');
                instance.disableInfiniteScrolling();
                return;
            } else if (typeof data === 'string') {
                data = data.trim();
                if (data === '') {
                    instance.showError('Content empty!');
                }
                // @todo fragment? empty respone? trim? see u
                let text = this.createDivElement(data, '')

                if (text.children.length > 0) {
                    let children = Array.from(text.children);
                    children.forEach(child => {
                        instance.data.targetContainer.append(child);
                    });
                } else {
                    instance.data.targetContainer.append(text);
                }
            } else {
                instance.showError('Unknown content!<br>Provide your own appendResults() in settings!');
                instance.disableInfiniteScrolling();
                return;
            }

            if (this.isFunction(this.settings.afterAppendResults)) {
                instance.settings.afterAppendResults(this, data, specialArguments);
            }

            instance.data.ready = true;

            if (this.settings.timeoutReload) {
                setTimeout(() => {
                    instance.checkAndLoadItems(false);
                }, 1000);
            }
        }).catch(reason => {
            console.error('Loading error:', reason);
            this.showError('Loading error: ' + reason);
            this.hideLoadingAnimation();
            this.disableInfiniteScrolling();
        });
    }

    showLoadingAnimation() {
        this.data.loadingAnimation.style.display = 'inline-block';
        this.data.loadMoreButton.style.display = 'none';
        this.data.ready = false;
    }

    hideLoadingAnimation() {
        this.data.loadingAnimation.style.display = 'none';
        this.data.loadMoreButton.style.display = 'inline-block';
        this.data.ready = true;
    }

    enableInfiniteScrolling() {
        this.data.actions.style.display= 'block';
        this.data.ready = true;
    }

    disableInfiniteScrolling() {
        // this.data.actions.style.display= 'none';
        this.data.loadingAnimation.style.display = 'none';
        this.data.loadMoreButton.style.display = 'none';
        this.data.ready = false;
    }

    showError(message) {
        this.data.errorContainer.innerText = message;
        this.data.errorContainer.style.display = 'block';
    }

    hideError() {
        this.data.errorContainer.style.display = 'none';
        this.data.errorContainer.innerText = '';
    }

    reset() {
        if (this.data.fetchRequest.hasOwnProperty('abort')) {
            this.data.fetchRequest.abort();
        }

        this.disableInfiniteScrolling();
        this.hideError();

        this.data.targetContainer.innerHTML = '';
        this.settings.page = 1;

        this.enableInfiniteScrolling();
        this.checkAndLoadItems(false);
    }

    /*var requestData;
    resetAndReload() {
        if (requestData.hasOwnProperty('abort')) {
            requestData.abort();
        }
        $object.items.empty();
        options.currentPage = 1;
        options.ready = true;
        checkAndLoadItems(false);
    };*/


    /*getParameterByName(name, url) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        var results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }*/
}
