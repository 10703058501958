'use strict';
import Ready from './ready.js';

class User {
    initialize() {
        this.bindNotificationPosition();
        this.bindAvatarSelectSave();
    }

    bindNotificationPosition() {
        let buttons = document.querySelectorAll('.js-notification-position button');
        if (buttons.length > 0) {
            buttons.forEach(button => {
                button.addEventListener('click', () => {
                    let notificationPosition = button.closest('form').querySelector('input[name=notificationPosition]:checked').value;
                    let positions = ['top-left', 'bottom-left', 'bottom-right'];
                    if (!notificationPosition || !positions.includes(notificationPosition)) {
                        notificationPosition = 'top-right';
                    }
                    toastr.options = Object.assign(toastr.options, {'positionClass': 'toast-' + notificationPosition});
                    toastr.remove();

                    toastr.error('Error message!', 'Error Title', {'timeOut': 0, 'extendedTimeOut': 0, 'tapToDismiss': false});
                    toastr.warning('Warning message!', 'Warning Title', {'timeOut': 0, 'extendedTimeOut': 0});
                    toastr.success('Success message!', 'Success Title', {'timeOut': 0});
                    toastr.info('Info message!', 'Info Title');
                });
            });
        }
    }

    bindAvatarSelectSave() {
        let radios = document.querySelectorAll('.js-avatar-select input[name="type"]');
        if (radios.length > 0) {
            radios.forEach(radio => {
                radio.addEventListener('change', () => {
                    radio.closest('form').submit();
                });
            });
        }
    }
}

Ready(() => {
    const user = new User();
    user.initialize();
});
