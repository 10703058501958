'use strict';

import Tagify from '@yaireo/tagify';
import Tools from './tools.js';

export default class JavaScript {
    initialize() {
        this.bindSafeConfirm();
        this.bindButtonReviewed();
        this.bindLinkNewWindow();
        this.bindTags();
        this.fileUploadPreview();
        this.bindPasswordStrength();
    }

    bindSafeConfirm() {
        const tools = new Tools();
        const className = 'js-safe-confirm';
        const elements = document.querySelectorAll('.' + className);
        if (elements.length > 0) {
            elements.forEach(element => {
                element.addEventListener('click', (event) => {
                    const targetElement = event.target.classList.contains(className) ? event.target : event.target.closest('.' + className);
                    const link = targetElement.dataset.href;
                    tools.dialogConfirm('Bestätigung', 'Bist du dir sicher?', () => {
                        document.location.href = link;
                    });
                });
            });
        }
    }

    bindButtonReviewed() {
        const tools = new Tools();
        const className = 'js-button-reviewed';
        const elements = document.querySelectorAll('.' + className);
        if (elements.length > 0) {
            elements.forEach(element => {
                element.addEventListener('click', (event) => {
                    const targetElement = event.target.classList.contains(className) ? event.target : event.target.closest('.' + className);
                    const id = targetElement.dataset.id;
                    const hideElement = targetElement.dataset.hide;
                    const data = {
                        id: id
                    };

                    fetch(targetElement.dataset.href, {
                        mode: 'cors',
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(response => {
                        if (!response.ok) {
                            throw new Error('Netzwerk Antwort war nicht in Ordnung!');
                        }
                        return response.json();
                    }).then(data => {
                        if (data.error === 0) {
                            tools.findElementAndSlideUp(hideElement, targetElement);
                            toastr.success('Bild ' + id + ' wurde geprüft.');
                        } else {
                            throw new Error('Es ist ein unbekannter Fehler aufgetreten! (ID: ' + id + ')');
                        }
                    }).catch(error => {
                        toastr.error('Es ist ein unbekannter Fehler aufgetreten!<br>' + error.toString() + ' (ID: ' + id + ')', '', {'timeOut': 0});
                        console.error('Error:', error);
                    });
                });
            });
        }
    }

    bindLinkNewWindow() {
        const className = 'js-new-window';
        const elements = document.querySelectorAll('.' + className);
        if (elements.length > 0) {
            elements.forEach(element => {
                element.addEventListener('click', (event) => {
                    const targetElement = event.target.classList.contains(className) ? event.target : event.target.closest('.' + className);

                    let options = {
                        width: 800,
                        height: 600,
                        top: 20,
                        left: 20,
                        scrollbars: 'no',
                        location: 'no',
                        directories: 'no',
                        status: 'no',
                        menubar: 'no',
                        toolbar: 'no',
                        resizable: 'no',
                        dependent: 'no'
                    };
                    if (targetElement.dataset.settings) {
                        options = Object.assign(options, JSON.parse(targetElement.dataset.settings));
                    }

                    let title = (targetElement.getAttribute('title') === undefined) ? 'js-new-window' : targetElement.getAttribute('title');
                    title.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');

                    let settings = "";
                    Object.entries(options).forEach(([key, value]) => {
                        settings += (settings.length > 0 ? ', ': '') + key + "=" + value;
                    });

                    window.open(targetElement.dataset.url, title, settings).focus();
                    event.preventDefault();
                });
            });
        }
    }

    bindTags() {
        let jsTags = document.querySelectorAll('.js-tags');
        if (jsTags.length > 0) {
            jsTags.forEach(jsTag => {
                new Tagify(jsTag, {
                    delimiters: ',', // Do not change! Add no spaces! Exclude spaces in 'pattern'.
                    duplicates: false,
                    suggestionsMinChars: 1,
                    maxSuggestions: 10,
                    maxTags: 20,
                    blacklist: [
                    ],
                    enforceWhitelist: false,
                    pattern: /^[a-zA-Z0-9äöüÄÖÜß_-]+$/,
                    whitelist: [
                        'mädel', 'mädels', 'schambereich'
                    ]
                });
            });
        }
    }

    fileUploadPreview() {
        const className = 'file-upload-preview';
        const elements = document.querySelectorAll('.' + className);
        if (elements.length > 0) {
            elements.forEach(element => {
                element.addEventListener('change', (event) => {
                    const targetElement = event.target.classList.contains(className) ? event.target : event.target.closest('.' + className);

                    let divImages = targetElement.parentNode.querySelector('.file-upload-images');
                    if (divImages) {
                        divImages.innerHTML = '';
                    } else {
                        divImages = document.createElement('div');
                        divImages.classList.add('file-upload-images');
                        targetElement.parentNode.insertBefore(divImages, targetElement);
                    }

                    if (targetElement.files) {
                        var filesAmount = targetElement.files.length;
                        var allowedMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

                        for (let i = 0; i < filesAmount; i++) {
                            var reader = new FileReader();
                            var file = targetElement.files[i];

                            if (allowedMimeTypes.indexOf(file.type) > -1) {
                                reader.onload = function(event) {
                                    $('<img>').prop('src', event.target.result).appendTo(divImages);
                                };
                            } else {
                                $('<div>' + file.name + '</div>').appendTo(divImages);
                            }
                            reader.readAsDataURL(file);
                        }
                    }
                });
            });
        }
    }

    bindPasswordStrength() {
        const passwordStrengths = document.querySelectorAll('.password-strength');
        if (passwordStrengths.length > 0) {
            const mapPasswordStrength = {
                0: 'Schlechtestes',
                1: 'Schlecht',
                2: 'Schwach',
                3: 'Gut',
                4: 'Stark',
                5: 'Extrem'
            };

            for (let i = 0; i < passwordStrengths.length; ++i) {
                let password = passwordStrengths[i];

                let text = document.createElement('p');
                text.className = 'password-strength-text';
                password.parentNode.insertBefore(text, password.nextSibling);

                let meter = document.createElement('meter');
                meter.className = 'password-strength-meter';
                meter.max = 5;
                password.parentNode.insertBefore(meter, password.nextSibling);

                password.addEventListener('input', (event) => {
                    let result = zxcvbn(event.target.value);

                    let isPasswordStrength5 = (
                        result.crack_times_display['offline_fast_hashing_1e10_per_second'] === 'centuries' &&
                        result.crack_times_display['offline_slow_hashing_1e4_per_second'] === 'centuries' &&
                        result.crack_times_display['online_no_throttling_10_per_second'] === 'centuries' &&
                        result.crack_times_display['online_throttling_100_per_hour'] === 'centuries'
                    );

                    if (isPasswordStrength5) {
                        event.target.parentNode.getElementsByClassName('password-strength-meter')[0].value = 5;
                    } else {
                        event.target.parentNode.getElementsByClassName('password-strength-meter')[0].value = result.score;
                    }

                    let text = '';
                    if (isPasswordStrength5) {
                        text = 'Stärke: ' + mapPasswordStrength[5];
                    } else if (event.target.value !== '') {
                        text = 'Stärke: ' + mapPasswordStrength[result.score];
                    }
                    event.target.parentNode.getElementsByClassName('password-strength-text')[0].innerHTML = text;
                });
            }
        }
    }
}
