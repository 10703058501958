'use strict';

import Fullscreen from './fullscreen.js';

export default class PictureShow {
    initialize() {
        let element = document.querySelector('.js-picture-show');
        if (element) {
            this.initializeElement(element);
        }
    }

    initializeElement(element) {
        let picture = element.querySelector('.picture');

        if (picture.closest('.fancybox__slide')) {
            picture.classList.remove('image-overlay', 'overlay-toolbar-bottom', 'invert');
            picture.querySelector('.overlay').remove();
            picture.addEventListener('click', (event) => {
                event.target.closest('.fancybox__container').querySelector('.fancybox__button--close').click();
            });
        } else {
            let fullscreen = new Fullscreen(picture.querySelector('img'));
            picture.addEventListener('click', () => {
                fullscreen.toggleFullscreen();
            });
        }
    }
}
