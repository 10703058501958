'use strict';

export default class AdministrationPanel {
    initialize() {
        let instance = this;

        if (!localStorage.getItem('administrationPanel')) {
            localStorage.setItem('administrationPanel', 'false');
        }

        let panels = document.querySelectorAll('.js-administration-panel');
        panels.forEach(element => {
            let toggler = element.querySelector('.toggler');
            let content = element.querySelector('.toggler-content');

            if (localStorage.getItem('administrationPanel') === 'true') {
                $(content).show();
                toggler.classList.add('open');
            }
        });

        document.addEventListener('click', (event) => {
            let selectors = '.js-administration-panel';
            if (event.target && event.target.matches && event.target.matches(selectors)) {
                instance.toggle(event.target);
            } else if (
                event.target && event.target.matches && event.target.matches('.toggler')
                && event.target.closest && event.target.closest(selectors)
            ) {
                instance.toggle(event.target.closest(selectors));
            }
        });
    }

    isHidden(element) {
        return (window.getComputedStyle(element).display === 'none')
    }

    toggle(element) {
        let instance = this;
        let toggler = element.querySelector('.toggler');
        let content = element.querySelector('.toggler-content');

        $(content).slideToggle('fast', 'swing', () => {
            if (!instance.isHidden(content)) {
                toggler.classList.add('open');
                localStorage.setItem('administrationPanel', 'true');
            } else {
                toggler.classList.remove('open');
                localStorage.setItem('administrationPanel', 'false');
            }
        });
    }
}
