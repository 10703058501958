'use strict';

// let fullscreen = new Fullscreen(element); fullscreen.toggleFullscreen();
export default class Fullscreen {
    constructor(element) {
        this.element = element
    }

    toggleFullscreen() {
        if (typeof this.getFullscreenElement() === 'undefined') {
            this.launchFullscreen(this.element);
        } else {
            this.exitFullscreen();
        }
    }

    launchFullscreen() {
        let instance = this;
        let method = this.element.requestFullscreen;
        if (!method) {
            ['mozRequestFullScreen', 'webkitRequestFullScreen', 'msRequestFullscreen'].forEach((testMethod) => {
                method = method || instance.element[testMethod];
            });
        }
        method.call(this.element);
    }

    exitFullscreen() {
        let method = document.exitFullscreen;
        if (!method) {
            ['mozCancelFullScreen', 'webkitExitFullscreen', 'msExitFullscreen'].forEach((testMethod) => {
                method = method || document[testMethod];
            });
        }
        method.call(document);
    }

    getFullscreenElement() {
        return document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
    }

    isFullscreenEnabled() {
        return document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
    }

    dumpFullscreen() {
        console.log('Fullscreen is enabled:', this.isFullscreenEnabled(), 'Element is:', this.getFullscreenElement());
    }

    initializeEventLog() {
        document.addEventListener('fullscreenchange', (event) => {
            console.log('fullscreenchange event!', event);
        });
        document.addEventListener('mozfullscreenchange', (event) => {
            console.log('mozfullscreenchange event!', event);
        });
        document.addEventListener('webkitfullscreenchange', (event) => {
            console.log('webkitfullscreenchange event!', event);
        });
        document.addEventListener('msfullscreenchange', (event) => {
            console.log('msfullscreenchange event!', event);
        });
    }
}
